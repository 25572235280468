import { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Button, Col, Spinner, Form, Collapse, ButtonGroup } from 'react-bootstrap';
// import Collapse  from 'react-bootstrap/Collapse';
import { User } from '../../models/interfaces/User';
import { Event, EventAdmin, isSameDay } from '../../models/interfaces/Event';
import { isCompetition, sortCompetitions } from '../../models/interfaces/Competition';
import { useUpdatableProviderToArray } from '../../helpers/useListProviderToArray';
import CompetitionCard from './CompetitionCard';
import { SportProvider } from '../../models/interfaces/SportProvider';
import EventRegistrationModal from './registration/EventRegistrationModal';
import DetailPageContainer from '../../components/containers/DetailPageContainer';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faInfo, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface Props {
    user?: User;
    event: Event;
    sportProvider: SportProvider;
}

export default function EventDetailPage({ user, event, sportProvider }: Props) {
    const history = useHistory();
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);

    const [eventAdmin, setEventAdmin] = useState<EventAdmin | undefined>(undefined);

    const updateEventAdmin = useCallback(
        (user) => {
            event
                .adminForUser(user)
                .then((eventAdmin) => {
                    setEventAdmin(eventAdmin);
                })
                .catch(() => {
                    setEventAdmin(undefined);
                });
        },
        [event, history]
    );

    useEffect(() => {
        if (user && !eventAdmin) {
            updateEventAdmin(user);
        }
        if (!user && eventAdmin) {
            setEventAdmin(undefined);
        }
    }, [event, user, updateEventAdmin, eventAdmin]);

    const { state: competitions, triggerUpdate: competitionsDidUpdate } =
        useUpdatableProviderToArray({
            provider: event.competitionProvider,
            isType: isCompetition,
            sortFn: sortCompetitions,
        });

    const handleHideModal = useCallback(() => {
        setShowRegistrationModal(false);
    }, []);

    const eventRegistration = event.registration;
    return (
        <DetailPageContainer
            titleView={
                <Col className="py-2">
                    <Row>
                        <h3>{event.attributes.name}</h3>
                    </Row>
                    <Row>
                        <EventSubtitleView event={event} />
                    </Row>
                </Col>
            }
            accessoryView={
                <ButtonGroup className="mb-1">
                    {eventRegistration && user && (
                        <>
                            <Button
                                onClick={() => {
                                    const registrationPrice = eventRegistration.attributes.price;
                                    if (registrationPrice && registrationPrice > 0) {
                                        history.push(`/eventRegistration/${event.id}`);
                                    } else {
                                        setShowRegistrationModal(true);
                                    }
                                }}
                            >
                                Register
                            </Button>
                            <EventRegistrationModal
                                user={user}
                                sportProvider={sportProvider}
                                registration={eventRegistration}
                                showModal={showRegistrationModal}
                                hideModal={handleHideModal}
                                onCompleteRegistrationRequest={() => {}}
                            />
                        </>
                    )}
                    {eventAdmin && (
                        <Button
                            variant="info"
                            onClick={() => {
                                history.push(`/events/${event.id}/admin`);
                            }}
                        >
                            Admin
                        </Button>
                    )}
                </ButtonGroup>
            }
            sportProvider={sportProvider}
        >
            <Container className="pt-2">
                <Row data-cy="event-competitions-container">
                    {!competitions ? (
                        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        competitions.map((competition) => (
                            <Col key={competition.id} xs={12} sm={12} md={6} className="mb-2">
                                <CompetitionCard
                                    competition={competition}
                                    showEvent={false}
                                    showMatches={true}
                                    sportProvider={sportProvider}
                                    onUpdateCompetition={() => {
                                        competitionsDidUpdate();
                                    }}
                                />
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
        </DetailPageContainer>
    );
}

const AttributeContent = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const AttributeText = styled.span`
    min-width: 100px;
`;

const AttributeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

function EventAttributeView({ icon, text }: { icon: IconProp; text: string }) {
    return (
        <AttributeContent>
            <FontAwesomeIcon className="mb-1" icon={icon} />
            <>
                <AttributeText className="text-muted mb-0">{text}</AttributeText>
            </>
        </AttributeContent>
    );
}

function EventSubtitleView({ event }: { event: Event }) {
    var dateString: string | undefined = undefined;

    if (event.attributes.displayCompetitionDates) {
        const startDate = event.attributes.competitionDateRange.startDate;
        const endDate = event.attributes.competitionDateRange.endDate;
        const singleDayEvent = isSameDay(startDate, endDate);

        if (singleDayEvent) {
            dateString = startDate.toLocaleDateString();
        } else {
            dateString = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
    }
    return (
        <AttributeContainer>
            {event.attributes.category && (
                <EventAttributeView icon={faLayerGroup} text={event.attributes.category.name} />
            )}

            {dateString && <EventAttributeView icon={faClock} text={dateString} />}
        </AttributeContainer>
    );
}
