import { Nav, Navbar, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Footer() {
    if (window.location !== window.parent.location) {
        return <></>;
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
            padding: '20px',
            borderTopWidth: '1px', 
            borderTopStyle: 'solid', 
            borderTopColor: 'rgb(0,0,0, 0.1)'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <a href='https://www.kap7.com.au'>
                    <Image
                        className="mx-2"
                        src={`${process.env.PUBLIC_URL}/kap7_logo.png`}
                        height={50}
                    />
                </a>
                <a href="https://www.goldbarsports.tech/"><small>In partnership with KAP7</small></a>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
            }}>
                <a href="https://www.goldbarsports.tech/"><small>Made by Gold Bar Sports Tech, LLC</small></a>
                <a href="mailto:support@cbwaterpolo.com"><small>Contact</small></a>
                <a href={`${process.env.PUBLIC_URL}/WPA_TOS_10_30_24.pdf`}><small>Terms & Conditions</small></a>
            </div>
        </div>        
    );
}
